jQuery.extend(jQuery.validator.messages, {
    invalidForm: "Niet alle velden zijn ingevuld. Wij hebben ze voor u uitgelicht",
    required: "<i class='fa fa-times'></i> Dit is een verplicht veld.",
    remote: "<i class='fa fa-times'></i> Dit e-mailadres is al bekend bij ons. <a href='#' onclick='gotoLogin(); return false;' style='color: red; text-decoration: underline'>Klik hier</a> om in te loggen.",
    email: "<i class='fa fa-times'></i> Vul hier een geldig email adres in.",
    url: "<i class='fa fa-times'></i> Dit is een verplicht veld.",
    date: "<i class='fa fa-times'></i> Vul hier een geldige datum in.",
    dateISO: "<i class='fa fa-times'></i> Vul hier een geldige datum in (ISO).",
    number: "<i class='fa fa-times'></i> Vul hier een geldig nummer in.",
    digits: "<i class='fa fa-times'></i> Vul hier alleen nummers in.",
    creditcard: "<i class='fa fa-times'></i> Vul hier een geldig credit card nummer in.",
    equalTo: "<i class='fa fa-times'></i> Vul hier dezelfde waarde in.",
    accept: "<i class='fa fa-times'></i> Vul hier een waarde in met een geldige extensie.",
    maxlength: "<i class='fa fa-times'></i> Vul hier maximaal {0} tekens in.",
    minlength: "<i class='fa fa-times'></i> Vul hier minimaal {0} tekens in.",
    rangelenth: "<i class='fa fa-times'></i> Vul hier een waarde in van minimaal {0} en maximaal {1} tekens.",
    range: "<i class='fa fa-times'></i> Vul hier een waarde in van minimaal {0} en maximaal {1}.",
    max: "<i class='fa fa-times'></i> Vul hier een waarde in kleiner dan of gelijk aan {0}.",
    min: "<i class='fa fa-times'></i> Vul hier een waarde in groter dan of gelijk aan {0}.",
    zipcode: "<i class='fa fa-times'></i> Vul een geldige postcode in.",
},typeof translation == 'object' && typeof translation.form == 'object' ? translation.form : {});
