export default function (callback, seconds) {
    var timer = typeof seconds == 'number' ? seconds : 300,
        resizeTimer;
    jQuery(window).scroll(function () {
        if (typeof resizeTimer != 'undefined')
            clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            try {
                callback.apply(this, arguments);
            } catch (e) {
                console.error(e);
            };
        }, timer);
    });
}