class Alert {

    constructor() {
        $('.alert.alert-auto-dismiss').each(function(it, el) {
            setTimeout(function() {
                jQuery(el).alert('close')
            },3500);
        });
    }
}

export default new Alert;