class AjaxSearch  {

	constructor(config) {
		this.config = Object.assign({
			scope: 'div#search-form',
			searchInputSelector: '[name="q"]',
			wrapper: 'div.ajax_search_results_wrapper'
		}, config);

		this.$scope = $(this.config.scope);

		if(this.$scope.length > 0) {
			this.init();
		}
	}

	init() {
		this.timeout = null;

		if (this.$scope.length > 0) {
			this.$scope.find(this.config.searchInputSelector).attr('autocomplete','off');
			this.$scope.find(this.config.searchInputSelector).on('keyup', this.handleKeyUp.bind(this));
		}
	}

	handleKeyUp(event) {
		if (event.keyCode == 13) { // enter
			return;
		} else if (event.keyCode == 8) { // backspace
		} else if (event.keyCode == 46) { // delete
		} else if(event.keyCode < 48) {
			return;
		}
		if(this.lastRequest) {
			this.lastRequest.abort();
		}
		let $input = $(event.currentTarget);
		clearTimeout(this.timeout);
		this.timeout = setTimeout(this.startSearch.bind(this, $input), 250);
	}

	startSearch(target) {
		var classFnc = target.val() != '' ? 'addClass' : 'removeClass';
		this.$scope[classFnc]('started-search loading');

		if( target.val() != ""){
			this.request(target);
		} else {
			jQuery('body').removeClass('autosuggest-active');
		}
	}

	request(obj) {

		var $scope = this.$scope;
		let searchPageUrl = obj.data('search-url');
		var $wrapper = jQuery(this.config.wrapper);
		this.lastRequest = jQuery.get(searchPageUrl + '?q=' + encodeURIComponent(jQuery(obj).val()) + '&autosuggest=true', (data) => {
			jQuery('body').addClass('autosuggest-active');
			$wrapper.html('');
			jQuery.each(data, function(key) {
				$wrapper.append(data[key].html);
			});
		}, 'json').always(function () {
			$scope.removeClass('loading');
		});
	}

}

export default AjaxSearch;
