class Hamburger {
	constructor() {
		this.$scope = $('.hamburger');

		if (this.$scope.length < 1) {
			return;
		}
		this.init();
	}

	init() {
		this.observer = new MutationObserver(mutations => {
			this.toggleHamburger(this.checkMutation(mutations));
		});

		this.observer.observe(this.$scope[0], {
			attributes: true,
			attributeFilter: ['aria-expanded']
		});
	}

	checkMutation(mutations) {
		if (mutations.length > 0) {
			return $(mutations[0].target).attr('aria-expanded') == 'true';
		}

		return false;
	}

	toggleHamburger(expanded) {
		if (!expanded) {
			return this.$scope.removeClass('is-active');
		}

		return this.$scope.addClass('is-active');
	}
}

export default new Hamburger();
