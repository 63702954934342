import jquery from 'jquery';
import 'bootstrap';

window.$ = window.jQuery = window.jquery = jquery;

$.ajaxSetup({
	headers: {
		'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	}
});
