import options from './options';

export default function ($el , subtract = 0) {
    var $el = $($el);
    if( $el.length <= 0 ) return;

    var body = $('html, body');
    body.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function () {
        body.stop();
    });

    subtract = subtract || options.scrollTo.subtract;

    var offset = $el.offset().top - subtract;
    body.animate({scrollTop: offset}, 'slow', function () {
        var offsetDif = $($el.eq(0)).offset().top - subtract;
        if( offset != offsetDif ){
            body.stop();
            wwdev.Bootstrap.scrollTo($el);
        }
        body.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
    });

    return false;
}