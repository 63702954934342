import options from './options';
import scrollTo from './scrollTo';
import addResizeFunction from './addResizeFunction';
import removeResizeFunction from './removeResizeFunction';
import addScrollFunction from './addScrollFunction';
import getResponsiveWidth from './getResponsiveWidth';

export default {
    options,
    scrollTo,
    addResizeFunction,
    removeResizeFunction,
    addScrollFunction,
    getResponsiveWidth,
}