var zipcode = {
	getCityAndStreet(code, streetnumber) {
		return $.post(location.href, {
			subject: 'zipcode',
			task: 'getCityAndStreet',
			zipcode: code,
			streetnumber: streetnumber
		});
	}
};

export default zipcode;