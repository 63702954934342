import BrowserDetect from './browserDetect';
import Events from '@wwdev/bootstrap/Events';
import jQuery from 'jquery-validation';

var activeLanguage = window.activeLanguage || 'nl';

switch (activeLanguage) {
    case 'en':
        require('./lang/en.js');
        break;
    case 'de':
        require('./lang/de.js');
        break;
    default:
        require('./lang/nl.js');
} 

var forms = { 
    preventSubmit:function($form){
        $form.find('.button-prevent-multiple-submit').prop('disabled', 'disabled');
    },
    reenableSubmit:function($form){
        $form.find('.button-prevent-multiple-submit').prop('disabled', false);
    },
    config : {
        lang: activeLanguage,
        unhighlight: function (element, errorClass, validClass) {
            if(!element.name) {
                console.log(' name niet gevonden', element );
                return true;
            }
            $(element).removeClass(errorClass).addClass(validClass);
            $(element.form).find("label[for='" + element.name + "']").removeClass(errorClass).addClass(validClass);

            if( this.numberOfInvalids() === 0 ){
                $(element).closest('.form-wrapper').find('.alert-danger').hide();
            }
        },

        submitHandler: function (form,e,isAjax = false) {
            var $form = $(form);
            forms.preventSubmit($form);
            Events.trigger('form.submit', [form]);
            if( ! isAjax )
                form.submit();
        },
        errorPlacement : function(error, element){
            console.log(element[0].type);
            switch (element[0].type) {
                case 'radio':
                case 'checkbox':
                    error.css('display','block');
                    error.css('width','100%');
					element.parent().append(error);
                    break;
                default:
                    error.insertAfter(element);
                    break;
            }

        },
        invalidHandler : function(event, validator){
            var $form = $(validator.currentForm);

            // 'this' refers to the form
            var errors = validator.numberOfInvalids();
            var $messageContainer = $form.closest('.form-wrapper').find('.messages');
            if (errors) {
                $messageContainer.html('<div class="alert alert-danger">' + jQuery.validator.messages.invalidForm + '</div>');
                $messageContainer.show();
            } else {
                $messageContainer.hide();
            }
        },
        rules: {
            password_confirmation: {
                equalTo: '[name="password"]'
            }
        }
    },
    ajaxConfig : {

        submitHandler: function(form,e) {
            var $form = $(form);
            forms.config.submitHandler(form,e,true);
            var formId = $form.find('[name="formId"]').val();
            $.ajax({
                type:'POST',
                url: $form.attr('action'),
                data: new FormData(form),
                cache: false,
                contentType: false,
                processData: false,
                dataType: 'json',
                success: forms.ajaxConfig.requestSuccess.bind(form),
                error:forms.ajaxConfig.requestError.bind(form),
                complete:forms.ajaxConfig.requestComplete.bind(form),
            });

            return false;
        },

        requestSuccess : function(data){
            var $form = $(this);
            if (typeof data.redirect_url != 'undefined' && data.redirect_url != '') {
                location.href = data.redirect_url;
                return false;
            } else {

                $form.html(data.success).addClass('alert alert-success');
                if ( $form.parent('[data-formsuccess]').length > 0 ) {
                    let newScrollTop = $form.parent('[data-formsuccess]').offset().top;
                    newScrollTop -= parseInt(getComputedStyle(document.documentElement)
                        .getPropertyValue('--header-height') );
                    window.scrollTo({
                        top: newScrollTop,
                        behavior : "smooth"
                    } );

                }
            }
        },

        requestError : function(data){

            $('.ajax-errors').html(
                forms.ajaxConfig.composeErrorMessge(data)
            );
            $('.ajax-errors').addClass('alert alert-danger').show();
        },

        composeErrorMessge : function(data){
            let errors = '<ul>';
            for( let error in data.responseJSON.errors){
                console.log(error);
                error = data.responseJSON.errors[error];
                console.log(error);
                errors+='<li>' + error[0] + '</li>';
            }
            errors+='</ul>';

            return errors;
        },

        requestComplete : function(data){
            forms.reenableSubmit($(this));
        }
    },

    init : function(selector = 'form'){
        var $forms = $(selector);

        $forms.find('input[name=js_check]').val(escape("wielinkwebsolutions"));
        $forms.find('input[name=browser_info]').val(BrowserDetect.getInfoAsJSON());
        var $ajaxForms = $forms.filter('.form-ajax');
        var $customForms = $forms.filter('.form-custom-submit');
        var $defaultForms = $forms.filter(':not(.form-ajax):not(.form-custom-submit):not(.no-validate)');
        $defaultForms.each(function(){
            forms.config.rules.password_confirmation.equalTo = $(this).find('[name="password"]');
            $(this).validate(forms.config)
        });
        $ajaxForms.each(function(){$(this).validate($.extend(true,{},forms.config, forms.ajaxConfig))});
        $customForms.each(function(){$(this).validate($.extend(true,{},forms.config, {submitHandler: null}))});
    }
};

export default forms;
