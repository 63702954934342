class Events {
    constructor(){
        this.$emitter = $({});
    }

    on(event,handler){
        this.$emitter.on(`app.${event}`,handler);
    }

    off(event,handler){
        this.$emitter.off(`app.${event}`,handler);
    }

    one(event,handler){
        this.$emitter.one(`app.${event}`,handler);
    }

    trigger(event,data){
        this.$emitter.trigger(`app.${event}`,data);
    }
}

export default new Events();