import jQuery from 'jquery';
import getResponsiveWidth from '../functions/getResponsiveWidth';

_initMetaviewport();
_initLinks();


function _initMetaviewport() {
    if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
        var msViewportStyle = document.createElement("style");
        msViewportStyle.appendChild(
            document.createTextNode(
                "@-ms-viewport{width:auto!important}"
            )
        );
        document.getElementsByTagName("head")[0].
        appendChild(msViewportStyle);
    }
    var f = document.getElementsByTagName("html"),
        e = f.length ? f[0].className : "",
        c = e.indexOf("ie7") > -1,
        b = e.indexOf("ie8") > -1,
        d;
    if (c || b) {
        return
    }

    function a() {
        var i = navigator.userAgent,
            h = "width=device-width, initial-scale=1.0, maximum-scale=1.5, minimum-scale=1.0",
            j, g;
        if (/android 2/i.test(i)) {
            return
        }
        j = /OS [1-6]_[0-9_]* like Mac OS X/i;
        g = /ipad/i.test(i);
        if (g && j.test(i) && i.indexOf("AppleWebKit") > -1) {
            return
        }
        if (g && document.getElementsByClassName("js-is-layout-responsive").length === 0) {
            return
        }
        if (getResponsiveWidth() <= 767) {
            h = "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
        }
        try {
            document.querySelector('meta[name="viewport"]').setAttribute("content", h)
        } catch (error) {
            // not all browsers support this selector
        }
    }
    a();
    jQuery(window).resize(function() {
        clearTimeout(d);
        d = setTimeout(a, 200)
    });
}
function _initLinks() {
    $("a[href$='.pdf'],a[href^='http://'],a[href^='www.'],a[href^='https://'],a[data-rel='external']")
        .not("a[href^='http://" + location.hostname + "']")
        .not("a[href^='https://" + location.hostname + "']")
        .not("[target='_self']")
        .attr('target','_blank');
}

$.fn.serializeObject = function() {
    let form = this[0];
    let formdata = new FormData(form);
    let object = {};
    for(let item of formdata) {
        object = Object.assign({[item[0]] : item[1]}, object);
    }
    return object;
}