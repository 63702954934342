import form from '@wwdev/forms';
class Expense {

    constructor() {

        $('[data-scope="expense"]').on('change', 'input[name="amount"]', (event) => {
            if ($(event.currentTarget).val() >= 50) {
                $(event.delegateTarget).find('input[name="receipt"]').attr('required', 'required');
            } else {
                $(event.delegateTarget).find('input[name="receipt"]').removeAttr('required');

            }
        });
    }
}

export default new Expense;