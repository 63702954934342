export default function (callback, seconds, namespace) {
    var timer = typeof seconds == 'number' ? seconds : 300,
        resizeTimer;
    jQuery(window).on(namespace ? 'resize.' + namespace : 'resize', function () {
        if (typeof resizeTimer != 'undefined')
            clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            try {
                callback.apply(this, arguments);
            } catch (e) {
                wwdev.reportError(e);
            }
        }, timer);
    });
}