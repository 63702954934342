class SimpleEditor {

    constructor() {
        $('#editControls a').click(function(e) {
            switch($(this).data('role')) {
                case 'h1':
                case 'h2':
                case 'p':
                    document.execCommand('formatBlock', false, $(this).data('role'));
                    break;
                default:
                    document.execCommand($(this).data('role'), false, null);
                    break;
            }
            $('#editor').triggerHandler('change');
            return false;
        });
        $('#editor').on('keyup change', function() {
            jQuery(this).next().val(jQuery(this).html());
        })
        $('#editor').on('paste',function(e) {
            alert('Plakken is hier niet toegestaan');
            e.preventDefault();
        });
    }
}

export default new SimpleEditor;