class CorporateFields {
	constructor() {

		this.initEventListeners()
	}

	initEventListeners() {
		let $corporateSwitch = $('[data-corporate-account]');
		$corporateSwitch.on('click', this.corporateSwitchListener.bind(this));
		$corporateSwitch.each(function(it,el) {
			this.toggleCorporateFields($(el));
		}.bind(this));
	}

	corporateSwitchListener(e) {
		this.toggleCorporateFields($(e.currentTarget));
	}

	toggleCorporateFields($input) {
		$('[data-corporate-account-display]').each((it, el) => {
			if ($input.is(':checked')) {
				return $(el).closest('.form-group').removeClass('d-none');
			}

			$(el).closest('.form-group').addClass('d-none');
		});
	}
}

export default new CorporateFields;