import zipcodeService from './zipcodeService';
import "@wwdev/jquery-extensions/triggerAll.js";
import Events from "@wwdev/bootstrap/Events";

const defaultConfig = {
	scope: '[data-zipcode="scope"]',
	zipcodeSelector: '[data-zipcode="zipcode"]',
	streetSelector: '[data-zipcode="street"]',
	streetNumberSelector: '[data-zipcode="streetNumber"]',
	townSelector: '[data-zipcode="town"]',
	countrySelector: '[data-zipcode="country"]',
	// defaultSuccess: true,
	// searchZipcodeEnabled: null,
	callback: false,
	errorElem: jQuery("<label/>", {
		'class': 'error error_address_lookup',
	}).html('We konden uw combinatie van postcode en huisnummer niet vinden. Klopt deze wél? Ga dan verder.')
};

class Zipcode {

	constructor($scope,config) {
		this.config = jQuery.extend(true, defaultConfig, config);
	
		this.$zipcode = $scope.find(this.config.zipcodeSelector);
		this.$streetNumber = $scope.find(this.config.streetNumberSelector);
		this.$street = $scope.find(this.config.streetSelector);
		this.$town = $scope.find(this.config.townSelector);
		this.$country = $scope.find(this.config.countrySelector);

		this.bindListeners();
	}

	static init(config) {
		config = jQuery.extend(true, defaultConfig, config);
		$(config.scope || 'document')
			.each(function(){ (new Zipcode($(this),config)) })
	}

	bindListeners() {
		this.$zipcode.on('change', this.findStreetAndTown.bind(this));
		this.$streetNumber.on('change', this.findStreetAndTown.bind(this));
		this.$country.on('change', this.findStreetAndTown.bind(this));
	}

	findStreetAndTown() {
		var country = this.$country.val();
		// Country ID and Name are both valid.
		if (country != 146 && country != 'Nederland') {
			this.$street.val('').removeAttr('readonly');
			this.$town.val('').removeAttr('readonly');
			return;
		}
		var zipcode = this.$zipcode.val();
		var streetNumber = this.$streetNumber.val();

		if ((zipcode == '' || zipcode.length < 6) || streetNumber == '') return;

		Events.trigger('wwdev.zipcode.getCityAndStreet.before',[zipcode,streetNumber]);
		zipcodeService.getCityAndStreet(zipcode, streetNumber)
			.then(this.onSuccess.bind(this))
			.fail(this.onError.bind(this))
			.then(this.onAlways.bind(this));
	}

	onSuccess(data) {

		this.$street.attr('readonly', 'readonly').val(data.street);
		this.$town.attr('readonly', 'readonly').val(data.city);
		this.config.errorElem.hide();

		this.handleCallback(data);
		Events.trigger('wwdev.zipcode.getCityAndStreet.success',[data]);
	}

	onError(res) {
		this.$street.val('').removeAttr('readonly');
		this.$town.val('').removeAttr('readonly');
		if (!jQuery.contains(document, this.config.errorElem.get(0))) {
			this.$street.before(this.config.errorElem);
		}

		this.config.errorElem.show().html('We konden uw combinatie van postcode en huisnummer niet vinden. Klopt deze wél? Ga dan verder.');

		this.handleCallback(res);
		Events.trigger('wwdev.zipcode.getCityAndStreet.error',[res]);
	}

	onAlways(e,b,c) {
		if (typeof this.$street.valid === 'function') {
			const events = 'keyup,change,blur';
			this.$street.triggerAll(events).valid();
			this.$town.triggerAll(events).valid();
		}
	}

	handleCallback(data) {
		if (typeof this.config.callback === 'function') {
			this.config.callback(data);
		}
	}
}

export {defaultConfig};
export default Zipcode;