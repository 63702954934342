jQuery.extend(jQuery.validator.messages, {
        invalidForm: "Not all fields are filled out. We have highlighted them for you",
        required: "This field is required.",
        remote: "Please fix this field.",
        email: "Please enter a valid email address.",
        url: "Please enter a valid URL.",
        date: "Please enter a valid date.",
        dateISO: "Please enter a valid date (ISO).",
        number: "Please enter a valid number.",
        digits: "Please enter only digits.",
        equalTo: "Please enter the same value again.",
        maxlength: $.validator.format( "Please enter no more than {0} characters." ),
        minlength: $.validator.format( "Please enter at least {0} characters." ),
        rangelength: $.validator.format( "Please enter a value between {0} and {1} characters long." ),
        range: $.validator.format( "Please enter a value between {0} and {1}." ),
        max: $.validator.format( "Please enter a value less than or equal to {0}." ),
        min: $.validator.format( "Please enter a value greater than or equal to {0}." ),
        step: $.validator.format( "Please enter a multiple of {0}." ),
        zipcode: "<i class='fa fa-times'></i> Fill out a valid zipcode.",
},typeof translation == 'object' && typeof translation.form == 'object' ? translation.form : {});

